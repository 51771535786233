<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">

            <el-row>
                <el-col :span="4">
                    <el-statistic title="账户余额">
                        <template slot="formatter">
                          {{commissionData.balance }}
                        </template>
                    </el-statistic>
                </el-col>
                <el-col :span="4">
                    <el-statistic title="未结佣金">
                        <template slot="formatter">
                          {{commissionData.un_settle_amount }}
                        </template>
                    </el-statistic>
                </el-col>
                <el-col :span="4">
                    <el-statistic title="已结佣金">
                        <template slot="formatter">
                          {{commissionData.is_settle_amount }}
                        </template>
                    </el-statistic>
                </el-col>
                <el-col :span="4">
                    <el-statistic title="剩余可提现">
                        <template slot="formatter">
                          {{commissionData.withdraw_able_amount }}
                        </template>
                    </el-statistic>
                </el-col>
                <el-col :span="4">
                    <el-statistic title="已取消佣金">
                        <template slot="formatter">
                          {{commissionData.cancel_settle_amount }}
                        </template>
                    </el-statistic>
                </el-col>
            </el-row>
            <br>
            <div>
                <el-radio-group v-model="tabPosition" style="margin-bottom:20px;" @change="changState">
                    <el-radio-button label="0">未结算佣金</el-radio-button>
                    <el-radio-button label="2">已结算佣金</el-radio-button>
                    <el-radio-button label="-1">被取消佣金</el-radio-button>
                </el-radio-group>
            </div>
            <!--            <div class="action">
                            <el-button type="primary" @click="exportFile">导出</el-button>
                            <el-upload style="display:inline-block;margin-left:10px" class="upload-demo" action="/i/admin/order/import/deliver" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" name="file">
                                <el-button size="small" type="success" v-if="tabPosition === '2'">导入发货单</el-button>
                            </el-upload>
                        </div>-->
            <!-- 表格内容 -->
            <el-table v-loading="loading" :data="pageInfo.list" element-loading-text="数据加载中" stripe border
                      ref="multipleTable" style="margin-top:20px" etype="expand">
                <el-table-column prop="id" label="id" width="80" align="center"></el-table-column>
                <el-table-column prop="ctime" label="订单日期" width="300" align="center"></el-table-column>
                <el-table-column prop="count" label="商品名称" width="300" align="center">
                    <template slot-scope="props">
                        <div>
                            <el-row>
                                <el-col :span="2">
                                    <div class="grid-content bg-purple">
                                        <el-image
                                            style="width: 60px; height: 60px"
                                            :src="props.row.goods[0].list.thumb"
                                            :preview-src-list="[props.row.goods[0].list.thumb]">
                                        </el-image>
                                    </div>
                                </el-col>
                                <el-col :span="5">
                                    <div class="grid-content bg-purple-light">商品标题:
                                        <el-tag>{{ props.row.goods[0].list.title }}</el-tag>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="count" label="商品规格" width="300" align="center">
                    <template slot-scope="props">
                        <div>
                            <el-row>
                                <span>规&nbsp;&nbsp;&nbsp;&nbsp;格:
                                {{ props.row.goods[0].list.attr }}
                                 购买数量:{{
                                            props.row.goods[0].list.number
                                        }}
                                    </span>
                            </el-row>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="count" label="商品详情" width="100" align="center" type="expand">
                    <template slot-scope="props">
                        <div v-for=" (goods ,index)  in props.row.goods" :key="index">
                            <el-row>
                                <el-col :span="2">
                                    <div class="grid-content bg-purple">
                                        <el-image
                                            style="width: 60px; height: 60px"
                                            :src="goods.list.thumb"
                                            :preview-src-list="[goods.list.thumb]">
                                        </el-image>
                                    </div>
                                </el-col>
                                <el-col :span="5">
                                    <div class="grid-content bg-purple-light">商品标题:
                                        <el-tag>{{ goods.list.title }}</el-tag>
                                    </div>
                                    <div class="grid-content bg-purple-light">规&nbsp;&nbsp;&nbsp;&nbsp;格:
                                        <el-tag>{{ goods.list.attr }}</el-tag>
                                    </div>
                                    <div class="grid-content bg-purple-light">商品&nbsp;&nbsp;SN:
                                        <el-tag>{{ goods.list.productsn }}</el-tag>
                                    </div>
                                </el-col>
                                <el-col :span="5">
                                    <div class="grid-content bg-purple">
                                        商品ID:
                                        <el-tag>{{ goods.list.id }}</el-tag>
                                        <br>购买数量:
                                        <el-tag>{{ goods.list.number }}</el-tag>
                                        <br>商品单价:
                                        <el-tag>{{ goods.list.price }}</el-tag>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="ordersn" label="订单编号" width="300" align="center"></el-table-column>
                <el-table-column prop="commission_price" label="结算金额" width="200" align="center"></el-table-column>
            </el-table>
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize"
                        v-on:refreshPageNum="refreshPageNum"/>
        </div>
        <!-- 新增 编辑 -->
        <el-dialog :title="title" :visible.sync="addVisible" width="600px" v-if="addVisible" class="dialog_box">
            <el-form ref="form" :model="form" label-width="120px" :rules="rules" label-position="left">
                <el-form-item label="备注 " prop="remark">
                    <el-input v-model="form.remark" placeholder="请输入备注"></el-input>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="addVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveData">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import Url from '@/components/Url';
import loadEvents from '@/utils/loading'
import {request} from '@/common/request';
import {deal} from '@/common/main';
import {mapMutations} from 'vuex';
import {CommonStateOk} from "@/common/const";

let loads = new loadEvents();
export default {
    components: {
        Breadcrumb,
        Pagination,
        Url
    },
    data() {
        return {
            tabPosition: 0,
            choseState: null,
            title: '新增用户',
            commissionData:{},
            addVisible: false,
            pageInfo: {},
            menuList: ['订单', '订单管理'],
            form: {},
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            },
            rules: {
                uid: [{
                    required: true,
                    message: '备注不能为空',
                    trigger: 'blur'
                }],
            },
            activeName: "",
        }
    },

    computed: {
        queryParams: {
            set: function (newValue) {
                this.$store.commit('initQueryParams', newValue)
            },
            get: function (newValue) {
                return this.$store.state.queryParams
            },
        }
    },
    beforeRouteEnter(to, from, next) {
        console.log('dddddd', to)
        console.log('dddddd', from)
        if (from.path === '/order-orderDetails') {
            next()
        } else {
            next(vm => {
                vm.queryParams = {page: 1, pagesize: 10}
            })
        }

    },
    created() {
        if (this.queryParams.state) {
            if (this.queryParams.state === 1) {
                this.tabPosition = 8
            }
            if (this.queryParams.state === 2) {
                this.tabPosition = 4
            }
            if (this.queryParams.state === 3) {
                this.tabPosition = 3
            }
            if (this.queryParams.state === 4) {
                this.tabPosition = 5
            }
            if (this.queryParams.state === 5) {
                this.tabPosition = 6
            }
            if (this.queryParams.state === 6) {
                this.tabPosition = 7
            }
        } else if (this.queryParams.pay_state) {
            if (this.queryParams.pay_state === 0) {
                this.tabPosition = 1
            }
            if (this.queryParams.pay_state === 2) {
                this.tabPosition = 2
            }
        } else {
            this.tabPosition = 'all'
        }
        this.loadData()
      this.getCommissionStatistics()
    },
    watch: {
        $route(from) {
            let ArticleEdit = from.fullPath.indexOf('/order-orderDetails')
            if (ArticleEdit !== -1) {
                this.loadData()
            }
        }
    },
    methods: {
        ...mapMutations({
            'initQueryParams': 'initQueryParams'
        }),

      // 分佣数据
      getCommissionStatistics() {
        request.get('/distributor/order/statistics', this.queryComm).then(res => {
          if (res.code === 1) {
            this.commissionData = res.data;
          }
        })
      },
        //商品列表
        loadData() {
            this.loading = true;
            let _this = this
            request.get('/order/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    this.pageInfo.list = ret.data.list;
                    this.pageInfo.list.map(function (val) {
                        val.pay_time = deal.timestamp(val.pay_time, 'unix');
                        val.count = val.goods.length
                        let index = val.goods.findIndex(item =>
                            item.list.refund_id != 0
                        )
                        if (index === -1) {
                            _this.$set(val, 'refund_id', 1)
                        } else {
                            _this.$set(val, 'refund_id', 2)

                        }
                        val.commission_price=0.00
                        if (val.goods!==null && val.goods.length >0 ){
                            val.goods.map(function (orderGoodsItem){
                                console.log(orderGoodsItem)
                              val.commission_price+=orderGoodsItem.list.commission_price
                            })
                        }
                        return val;
                    })
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },

        changeQuery() {
            this.queryParams.page = 1;
            if (this.queryParams.time) {
                let arr = Object.assign({}, this.queryParams.time)
                this.queryParams.ctime = deal.timestamp(arr[0]);
                this.queryParams.etime = deal.timestamp(arr[1]);
            } else {
                delete this.queryParams.ctime
                delete this.queryParams.etime
            }
            this.loadData();
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val;
            if (this.queryParams.time) {
                let arr = Object.assign({}, this.queryParams.time)
                this.queryParams.ctime = deal.timestamp(arr[0]);
                this.queryParams.etime = deal.timestamp(arr[1]);
            } else {
                delete this.queryParams.ctime
                delete this.queryParams.etime
            }
            this.loadData()
        },
        refreshPageNum(val) {
            this.queryParams.page = val;
            if (this.queryParams.time) {
                let arr = Object.assign({}, this.queryParams.time)
                this.queryParams.ctime = deal.timestamp(arr[0]);
                this.queryParams.etime = deal.timestamp(arr[1]);
            } else {
                delete this.queryParams.ctime
                delete this.queryParams.etime
            }
            this.loadData()
        },
        changState(e) {
            console.log(typeof e)
            this.queryParams.page = 1
            this.queryParams.pagesize = 10
            switch (this.tabPosition) {
                case '0':
                    delete  this.queryParams.state
                    this.queryParams.pay_state = 2
                    this.queryParams.is_settlement=0
                    break;
                case '2':
                    this.queryParams.pay_state = 2
                    this.queryParams.state = 2
                    this.queryParams.is_settlement=2
                    break;
                case '-1':
                    this.queryParams.pay_state = 2
                    this.queryParams.is_settlement=0
                    this.queryParams.state = 5
                    break;

            }
            this.loadData()
        },
    }
};
</script>

<style scoped>
.red {
    color: #ff0000;
}

.green {
    color: green;
}

/* 对话框样式 */

.dialog_box >>> .el-dialog__body {
    padding: 20px 30px 20px 60px;
}

.dialog_box >>> .el-dialog__header {
    padding: 30px 30px 10px;
}

.dialog_box .el-form-item--small.el-form-item {
    margin-bottom: 20px;
}
</style>
